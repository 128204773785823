import { Language } from 'dg-web-shared/lib/Localized';
import { SavedCreditCardAliasResponse } from 'dg-web-shared/model/PaymentAlias';
import {
    AppMode,
    TenantAllowedBarrierGateVehicleIdentification,
    TenantAllowedEnforcedVehicleIdentification,
} from 'dg-web-shared/model/TenantEnums.ts';

export const MAX_CONTAINER_WIDTH = 444;

export enum AliasInvalidReason {
    ONBOARDING_REQUIRED = 'ONBOARDING_REQUIRED',
    NOT_FOUND = 'NOT_FOUND',
    LAST_DIRECT_FAILED = 'LAST_DIRECT_FAILED',
    EXPIRED = 'EXPIRED',
}

export interface ParkingaboUser {
    customerNr: string;
    email: string;
    requestedEmail: string | null;
    customerState: CustomerTenantState;
    firstName: string | null;
    lastName: string | null;
    language: Language;
    alias: SavedCreditCardAliasResponse;
    aliasInvalidReason: AliasInvalidReason | null;
    /*
     * @deprecated use tenant from url path and not this user
     */
    tenantId: number;
    overduePaymentsAmountRappen: number;
}

export interface Tenant {
    tenantId: number;
    tenantName: string;
    appMode: AppMode;
    allowedEnforcedVehicleIdentification: TenantAllowedEnforcedVehicleIdentification | null;
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null;
}

export const enum CustomerTenantState {
    ACTIVE = 'ACTIVE',
    LOCKED = 'LOCKED',
    ARCHIVED = 'ARCHIVED',
}
