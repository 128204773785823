import { createContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { envIsProduction } from 'dg-web-shared/lib/Environment.ts';

type QRCodeScanner = () => void;

interface NativeBridge {
    scanQRCode: QRCodeScanner | null;
}

export const getBridge = (): NativeBridge | null => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (w.android) {
        return w.android;
    } else if (w.webkit && w.webkit.messageHandlers) {
        return {
            scanQRCode: scanQRCodeHandler(),
        };
    } else {
        return null;
    }
};

function scanQRCodeHandler(): QRCodeScanner | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.scanQRCode
    ) {
        return () => w.webkit.messageHandlers.scanQRCode.postMessage(null);
    }
    return null;
}

const enum NativeDeviceMessageType {
    QRScannerCallback = 'QRScannerCallback',
}

type MessageFromNativeDeviceCallback = (
    type: NativeDeviceMessageType,
    message: string,
) => void;

type WindowWithBridge = {
    messageFromNativeDevice: MessageFromNativeDeviceCallback;
} & Window &
    typeof globalThis;

const NativeContext = createContext({});

export function NativeContextProdivder() {
    const navigate = useNavigate();
    useEffect(() => {
        (window as WindowWithBridge).messageFromNativeDevice = (
            type: NativeDeviceMessageType,
            message: string,
        ) => {
            switch (type) {
                case NativeDeviceMessageType.QRScannerCallback: {
                    const pathname = parseToParkingaboURL(message);
                    if (!pathname) {
                        navigate('/login/qr-code-parsing-failed');
                        return 'ok';
                    }
                    navigate(pathname);
                    return 'ok';
                }
                default:
                    return 'unknownMessageFromDevice';
            }
        };
    }, []);

    return (
        <NativeContext.Provider value={{}}>
            <Outlet />
        </NativeContext.Provider>
    );
}

function parseToParkingaboURL(message: string): string | null {
    try {
        const url = new URL(message);
        return getParkingaboPathname(url);
    } catch (_) {
        return null;
    }
}

function getParkingaboPathname(url: URL) {
    const prodValidHostsPattern = /^(www\.)?parkingabo\.ch$/;
    if (envIsProduction() && !url.host.match(prodValidHostsPattern)) {
        return null;
    }
    if (
        !url.host.match(
            /^parkingabo\.ch$|^parkingabo\.test\.digitalparking\.ch$|^parkingabo\.([\w-]+)\.digitalparking\.dev$/,
        )
    ) {
        return null;
    }
    return url.pathname;
}
