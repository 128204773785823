import { css } from '@emotion/css';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Clickable } from 'dg-web-shared/ui/Clickable.tsx';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import { getBridge } from './Native.tsx';
import { QRCode } from 'dg-web-shared/ui/icons/QRCode.tsx';
import { Box } from '@mui/material';

export function QRScanButton() {
    const bridge = getBridge();
    if (!bridge || !bridge.scanQRCode) {
        return null;
    }

    const buttonHoverStyle = {
        background: Colors.lightblue,
        color: Colors.white,
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
            }}
        >
            <Clickable
                element="div"
                className={css({
                    ...paper(2),
                    opacity: 1.0,
                    backgroundColor: Colors.white,
                    color: Colors.blue,
                    borderRadius: '2px',
                    display: 'flex',
                    paddingRight: '12px',
                    paddingLeft: '4px',
                    '@media(hover: hover)': {
                        ':hover': buttonHoverStyle,
                    },
                    '@media(hover: none)': {
                        ':active': buttonHoverStyle,
                    },
                })}
                onClick={() => {
                    const bridge = getBridge();
                    if (bridge && bridge.scanQRCode) {
                        bridge.scanQRCode();
                    }
                }}
            >
                <div
                    className={css({
                        height: '48px',
                        width: '40px',
                        marginLeft: '12px',
                    })}
                >
                    <QRCode />
                </div>{' '}
                <span
                    className={css({
                        paddingTop: '15px',
                        paddingLeft: '15px',
                        textTransform: 'uppercase',
                        ...Typo.body,
                    })}
                >
                    <Localized
                        de="Registrierung"
                        fr="Inscription"
                        it="Registrazione"
                        en="Sign up"
                    />{' '}
                </span>
            </Clickable>
        </Box>
    );
}
